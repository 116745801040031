
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    async function init() {
      store.commit("setLoadingGlobal", true);
      await store.dispatch(`BankModule/${Actions.FETCH_LIST_BANK}`);
      await store.dispatch(`BankModule/${Actions.FETCH_LIST_BANK_NAME}`);
      store.commit("setLoadingGlobal", false);
    }
    onMounted(() => {
      setCurrentPageTitle("Bank Information");
      init();
    });

    return { isLoading };
  },
});
